<template>
  <div class="con-wrap">
    <CarrotTitle title="나의 커뮤니케이션 서약">
      - 아래의 CARROTian 커뮤니케이션 서약을 읽고 녹음하여 제출해주세요.
    </CarrotTitle>

    <div class="board mt-40">
      <table class="table-col">
        <thead>
          <tr>
            <th>최근제출일</th>
            <th>녹음 파일</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p v-if="comp.myCommAgreement.createdate">
                {{ comp.myCommAgreement.createdate }}
              </p>
              <p v-else>-</p>
              <p></p>
            </td>
            <td height="100">
              <div class="record-file-box">
                <ul class="file-wrap">
                  <li class="file-name">
                    <p class="file-p1">이전에 제출한 파일 :</p>
                    <p v-if="comp.myCommAgreement.file1_name">
                      <a
                        :href="comp.myCommAgreement.file1_url"
                        :download="comp.myCommAgreement.file1_name"
                        target="_blank"
                        class="line-clamp-1"
                      >
                        {{ comp.myCommAgreement.file1_name }}
                      </a>
                    </p>
                    <p v-else>-</p>
                  </li>
                  <li class="file-name">
                    <p class="file-p1">현재 선택한 파일 :</p>
                    <p v-if="comp.selectedFileName">
                      <a
                        :href="comp.myCommAgreement.file1_url"
                        :download="comp.myCommAgreement.file1_name"
                        target="_blank"
                        class="line-clamp-1"
                      >
                        {{ comp.selectedFileName }}
                      </a>
                    </p>
                    <p v-else>-</p>
                  </li>
                </ul>

                <ul class="btn-wrap">
                  <li>
                    <div class="file-upload">
                      <input
                        type="file"
                        @change="comp.handleFileSelection($event)"
                        id="file"
                      />
                      <label for="file">{{
                        comp.selectedFileName ? "파일 재선택" : "파일 선택"
                      }}</label>
                    </div>
                  </li>
                  <li v-if="comp.upfile">
                    <p class="go-submit" @click="comp.myCommAgreementAdd()">
                      제출하기
                    </p>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-40 pledge-box">
        <p class="pledge-title">CARROTian 커뮤니케이션 서약서</p>
        <div>
          <ul>
            <li>
              <p>1.목적</p>
              <ul>
                <li>
                  <p>
                    본 서약서는 CARROT의 일원으로서 좋은 회사문화 창달을 위하여
                    커뮤니케이션의 목적과 그 방법을 명시하고 업무와
                    조직구성원간에 Positive Communication을 활성화하기 위함이다.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>2.서약내용</p>
              <ul>
                <li>
                  <div>
                    <p>1)</p>
                    <p>
                      회사의 경영기밀사항은 어떠한 경우라도 개인의 호기심에 의해
                      알려고 하지 않으며,<br />입수된 경영기밀 사항은 절대
                      타인에게 전파하지 않는다.<br />Ex) 임직원의 연봉 및 그에
                      준하는 사항 / 회사의 기밀 인사정책 / 직무수행과 관계없는
                      개인의 인사 사항 등
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>2)</p>
                    <p>
                      개인의 성향과 기호에 따른 타인에 대한 비난, 비판, 불평
                      등을 생산해내지 않는다.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>3)</p>
                    <p>
                      어떠한 경우라도 개인적인 성향과 기호에 따른 사람 나누기를
                      금한다.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>4)</p>
                    <p>
                      기타 캐럿문화에 저해되는 Negative Communication은 절대
                      생산하거나 동조 및 전달하지 않는다.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>5)</p>
                    <p>
                      CARROT의 인트라넷인 당근농장과 이메일 접속 ID (<span
                        class="email"
                        >{{ comp.myInfo.email }}</span
                      >) 및 비밀번호는 절대 타인과 공유하지 않으며,<br />ID와
                      비밀번호가 타인에게 유출 시 인사상 책임을 진다.
                    </p>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
          <p class="pledge-warn">
            본인은 위 사항을 분명히 인지하였으며,<br />회사의 경영기밀사항을
            누설하거나,<br />업무와 관련 없는 개인적인 선호에 의하여 사람을
            비방하고 비평함으로써<br />회사문화에 역행하는 행위를 했을 경우에
            인사상 불이익을 당할 수 있음에 동의합니다.
          </p>
          <p class="pledge-date">
            {{ comp.getDay.year }}년 {{ comp.getDay.month }}월
            {{ comp.getDay.day }}일
          </p>
          <p class="pledge-name">
            서약자 이름 : {{ comp.myInfo.ename }}({{ comp.myInfo.kname }})
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import axios from "@/plugins/axios.js";

export default {
  layout: "myPIMS",
  components: {},
  setup() {
    const comp = reactive({
      myCommAgreement: {
        //내 서약서
        createdate: "", //날짜, 시간
        file1_name: "", //파일 이름
        file1_url: "", //파일 주소
      },
      myInfo: {
        //내 정보
        email: "", //이메일
        ename: "", //영어 이름
        kname: "", //한글 이름
      },
      selectedFileName: "", //업로드 할 파일
      upfile: null, //파일 파라미터 키

      getDay: {
        // 오늘 날짜
        year: new Date().getFullYear(), // 년
        month: new Date().getMonth() + 1, //월
        day: new Date().getDate(), //일
      },

      // 내 서약서 조회
      searchMyCommAgreement: async () => {
        axios.get("/rest/mypims/getMyCommAgreement").then((res) => {
          if (res.data.err == 0) {
            comp.myCommAgreement = {
              ...res.data.data,
              createdate: res.data.data.createdate.split(" ")[0],
              file1_url: res.data.data.file1_url,
              file1_name: res.data.data.file1_name,
            };
          } else {
            console.info(res.data.err_msg);
          }
        });
      },

      // 내 정보 조회
      getMyInfo: () => {
        axios.get("/rest/mypims/getMyInfo").then((res) => {
          if (res.data.err == 0) {
            comp.myInfo.email = res.data.email;
            comp.myInfo.ename = res.data.ename;
            comp.myInfo.kname = res.data.kname;
          } else {
            console.info(res.data.err_msg);
          }
        });
      },

      // 파일 업로드
      handleFileSelection(e) {
        const file = e.target.files[0];
        comp.selectedFileName = file ? file.name : "";
        comp.upfile = file;
      },

      // 내 서약서 업로드
      myCommAgreementAdd: () => {
        const formData = new FormData();
        formData.append("upfile", comp.upfile);

        axios
          .post("/rest/mypims/myCommAgreementAdd", formData)
          .then(async (res) => {
            if (res.data.err == 0) {
              window.alert("파일이 등록 되었습니다.");
              await comp.searchMyCommAgreement();
              comp.upfile = null;
              comp.selectedFileName = "";
            } else {
              window.alert(res.data.err_msg);
            }
          });
      },
    });

    onMounted(() => {
      comp.searchMyCommAgreement();
      comp.getMyInfo();
    });

    return { comp };
  },
};
</script>

<style lang="scss" scoped>
.record-file-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .file-wrap {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .file-name {
      display: flex;
      gap: 4px;

      p {
        text-align: left;

        &.file-p1 {
          flex: none;
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }

  .btn-wrap {
    flex: none;
    display: flex;

    flex-direction: column;
    align-items: center;
    gap: 4px;

    li {
      width: 100%;
    }

    .file-upload {
      display: flex;
      align-items: center;
      gap: 4px;

      input {
        display: none;
      }

      label {
        font-size: 14px;
        padding: 4px;
        background-color: #eee;
        border: 1px solid #000;
        cursor: pointer;

        &:hover {
          background-color: #e4e4e4;
        }
      }
    }

    .go-submit {
      padding: 4px 10px;
      border: 1px solid #ff854a;
      cursor: pointer;

      &:hover {
        background-color: #ff854a;
        color: #fff;
      }
    }
  }
}

.pledge-box {
  border: 1px solid #353535;

  .pledge-title {
    padding: 20px 0;
    background-color: #353535;
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }

  > div {
    padding: 60px 40px;

    > ul {
      display: flex;
      flex-direction: column;
      gap: 25px;
      padding-bottom: 50px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 50px;

      > li {
        > p {
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        > ul {
          display: flex;
          flex-direction: column;
          gap: 15px;

          > li {
            p {
              font-size: 18px;
              line-height: 30px;

              > .email {
                font-weight: 500;
                color: #ff854a;
              }
            }

            > div {
              display: flex;
              gap: 4px;
            }
          }
        }
      }
    }

    .pledge-warn {
      font-size: 20px;
      font-weight: 700;
      color: #c22424;
      text-align: center;
      margin-bottom: 50px;
    }

    .pledge-date {
      font-size: 17px;
      text-align: center;
      margin-bottom: 5px;
    }

    .pledge-name {
      font-size: 17px;
      font-weight: 500;
      text-align: center;
    }
  }
}
</style>
